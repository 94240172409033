import React from "react";
import styled, { withTheme } from "styled-components";
import NukaCarousel from "nuka-carousel";

import Box from "../../containers/box";
import Heading from "../../typography/heading";
import Text from "../../typography/text";

const Root = styled(NukaCarousel)`
  > * {
    text-align: center;
  }

  .slider-control-bottomcenter {
    bottom: -56px !important;

    @media (min-width: ${p => p.theme.screenM}) {
      bottom: -64px !important;
    }
  }

  .active svg {
    fill: ${p => p.theme.green};

    > * {
      width: 100%;

    }
  }

  .paging-dot {
    margin: 0 4px;
    width: ${p => `${p.dotSize}px`};
    height: ${p => `${p.dotSize}px`};

    circle {
      r: ${p => `${p.dotSize / 2}px`};
      cx: ${p => `${p.dotSize / 2}px`};
      cy: ${p => `${p.dotSize / 2}px`};
    }
  }

  h5 {
    max-width: none;
  }
`;

const AboutAppCarousel = () => {
  const dotSize = 10;

  return (
    <Root
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      defaultControlsConfig={{
        pagingDotsStyle: {
          fill: `#C4C4C4`,
        },
      }}
      dotSize={dotSize}
    >
      <Box>
        <Heading as="h3">Watch technique videos</Heading>
        <Text>Technique and fitness videos prepare your children for every aspect of the game.</Text>
      </Box>
          
      <Box>
        <Heading as="h3">Try the challenges</Heading>
        <Text>The challenges allow your child to improve technique, have fun and monitor progress.</Text>
      </Box>

      <Box>
        <Heading as="h3">Unlock trophies</Heading>
        <Text>Your child can unlock trophies as they achieve certain targets within the challenges.</Text>
      </Box>

      <Box mb="60px">
        <Heading as="h3">Training from coaches</Heading>
        <Text>Coaches create team and player development plans to improve individuals and ultimately benefitting the whole team.</Text>
      </Box>
    </Root>
  );
};

export default withTheme(AboutAppCarousel);