import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

import Box from "../../containers/box";
import Text from "../../typography/text";
import LinkButton from "../../links/linkButton";
import Circle from "../../globals/circle";
import Image from "../../globals/image";
import { Absolute } from "../../containers/position";

const CTA = styled(Box)`
    position: relative;
    text-align: center;
    overflow: hidden;
`;

const ContentWrap = styled(Box)`
    > * {
      position: relative;
      z-index: 5;
      color: ${p => p.theme.white};
    }
    div:first-of-type {
        font-family: ${p => p.theme.headingFont};
    }
`;

const ImgAbsolute = styled(Absolute)`
  display: none;

  @media (min-width: ${p => p.theme.screenL}) {
    display: block;
    transform: rotate(30deg);
  }
`;

const ReferralCTA = ({ mainText, subText, organisation, bgImg, buttonData, theme, ...rest }) => {
  const { ctaBtnText, ctaBtnLink } = buttonData;
  let link = `https://app.toptekkers.com?referrer=${organisation}`;
  if (ctaBtnLink) {
    link = ctaBtnLink;
  }

  return (
    <CTA {...rest}>
      <ContentWrap mx="auto" py={[`70px`, null, `140px`]} px="28px">
        <Text as="h2" mx="auto" maxWidth="750px" fontFamily={theme.headingFont} fontSize={[`28px`, null, `36px`]} lineHeight={[`38px`, null, `46px`]} mb="20px">{mainText}</Text>
        <Text mb={[`20px`, null, `33px`]} mx="auto" maxWidth="868px" fontSize={[theme.rem18, null, theme.rem24]} lineHeight={[`28px`, null, `38px`]}>{subText}</Text>
        <LinkButton as="a" href={link} bg={theme.yellow}>{ctaBtnText || `Create an account`}</LinkButton>
      </ContentWrap>

      <Circle
        size="l"
        bgColor={theme.blue}
        right="12%"
        bottom="-90px"
        zIndex="3"
      />
      <Circle
        size="l"
        bgColor={theme.yellow}
        right="-90px"
        top="-90px"
        zIndex="3"
      />
      <Circle
        size="s"
        bgColor={theme.pink}
        left="12%"
        top="-12px"
        zIndex="3"
      />
      <Circle
        size="l"
        bgColor={theme.orange}
        left="-90px"
        top="22%"
        zIndex="3"
      />

      <ImgAbsolute width="100%" top="20%" left="6%">
        <Image fluid={bgImg} alt="A digram of the lines that mark out a football pitch" />
      </ImgAbsolute>
    </CTA>
  );
};

ReferralCTA.propTypes = {
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  bgImg: PropTypes.object.isRequired,
  buttonData: PropTypes.object.isRequired,
};

export default withTheme(ReferralCTA);