import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { withTheme } from "styled-components";

import Layout from "../components/containers/layout";
import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Box from "../components/containers/box";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Image from "../components/globals/image";
import { Wave, PlayButton } from "../components/svg/icons";
import LinkButton from "../components/links/linkButton";
import Video from "../components/video/video";
import Grid from "../components/containers/grid";
import PortalType from "../components/pages/landingPageReferral/portalType";
import { Absolute } from "../components/containers/position";
import Circle from "../components/globals/circle";
import ReferralCTA from "../components/pages/landingPageReferral/referralCTA";
import AboutApp from "../components/pages/landingPageReferral/aboutApp";
import AboutAppCarousel from "../components/pages/landingPageReferral/aboutAppCarousel";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import TestimonialCarousel from "../components/pages/landingPageReferral/testimonialCarousel";
import AppStoreLinks from "../components/links/appstoreLinks";
import HTMLRenderer from "../utils/htmlRenderer";

const HeroWaveBox = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;

  div:first-of-type {
    z-index: 2;
  }

  svg {
    margin-top: -4px;
  }

  place-items: center;
  text-align: center
`;

const WaveBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-left: -200px;
  
  svg {
    margin-top: -4px;
    width: 100%;
    min-width: 1200px;
  }

  @media (min-width: ${p => p.theme.screenM}) {
    margin-left: 0;
  }
`;
const WaveBoxFlipped = styled(WaveBox)`
  svg {
    transform: scaleX(-1);
  }
`;

const GridCenteredItems = styled(Grid)`
  > * {
    place-items: center;
    text-align: center
  }
`;

const Quote = styled(Heading)`
  span {
    color: ${p => p.theme.green};
  }
`;

const BubbleShapeAbsolute = styled(Absolute)`
  display: none;

  @media (min-width: ${p => p.theme.screenM}) {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1;
    max-width: 780px;
  }
`;

const AboutAppGrid = styled(Grid)`
  display: grid;

  @media (min-width: ${p => p.theme.screenL}) {
    display: none;
  }
`;

const LinkButtonStyled = styled(LinkButton)`
  border: 0;
  cursor: pointer;
  width: 100%;
  max-width: 278px;
  margin-top: 48px;

  @media (min-width: ${p => p.theme.screenM}) {
    max-width: 238px;
    margin-top: 38px;
  }
`;

const VideoWrap = styled(Box)`
  cursor: pointer;

  &:first-child * {
    height: 100%;
  }

  img {
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: ${p => p.theme.borderRadiusContent};
  }

  &:hover {    
    svg g {
      opacity: .6;
    }
  }
`;

const SvgAbsolute = styled(Absolute)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  svg g {
    transition: ${p => p.theme.transitionDefault};
  }
`;

const LandingPageReferral = ({ data, theme }) => {
  const [showCover, setShowCover] = useState(true);

  const {
    hero_title: heroTitle,
    hero_paragraph: { html: heroPara },
    cta_title: ctaTitle,
    cta_paragraph: ctaPara,
    button_text: ctaBtnText,
    button_link: ctaBtnLink,
    org_display_name: orgDisplay,
    org_tag: orgTag,
    organisation_logo: orgLogo,
    video: videoId,
  } = data.prismicLandingPageReferral.data;
  const { testimonial_list: testimonialList } = data.testimonials.edges[0].node.data.testimonials.document.data;
  const buttonData = { ctaBtnText, ctaBtnLink };

  let player;
  const loadVideo = () => {
    player = new window.YT.Player(`youtube-player`, {
      videoId,
      playerVars: { rel: 0 },
    });
  };

  // On mount, check to see if the API script is already loaded
  useEffect(() => {
    // If not, load the script asynchronously
    if (!window.YT) {
      const tag = document.createElement(`script`);
      tag.src = `https://www.youtube.com/iframe_api`;
  
      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo;
  
      const firstScriptTag = document.getElementsByTagName(`script`)[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      loadVideo();
    }
  }, []);

  const coverClicked = (ytPlayer) => {
    setShowCover(false);
    ytPlayer.playVideo();
  };

  const handleReceiveOfferClick = () => {
    const location = document.getElementById(`refCTA`);
    location.scrollIntoView({ behavior: `smooth`, block: `center` });
  };

  return (
    <Layout>
      <SEO
        title={`${orgDisplay} & TopTekkers Partnership`}
        description={`${orgDisplay} have joined forces with TopTekkers to offer their members an exclusive deal.`}
        path={data.prismicLandingPageReferral.uid}
        noIndex
      />

      {/* 1. Hero */}
      <Section pb="0" pt={[`20px`, null, `30px`]} className="posRelative">
        <GridCenteredItems>
          <Box gridColumn="2 / span 10">
            <Heading maxWidth="none" mx="auto" mb="36px">
              {heroTitle.text}
            </Heading>

            {/* Overriding styles not showing */}
            <HTMLRenderer
              html={heroPara}
              componentOverrides={{
                p: Comp => props => (
                  <Comp
                    fontSize={[theme.rem18, null, theme.rem24]}
                    lineHeight={[`28px`, null, `38px`]}
                    color={theme.blueDark}
                    {...props}
                  />
                ),
                strong: Comp => props => (
                  <Comp
                    fontSize={[theme.rem18, null, theme.rem24]}
                    lineHeight={[`28px`, null, `38px`]}
                    color={theme.blueDark}
                    {...props}
                  />
                ),
              }}
            />

            <Text
              fontSize={[theme.rem18, null, theme.rem24]}
              lineHeight={[`28px`, null, `38px`]}
              mb="0"
              color={theme.blueDark}
            >
              We&#8217;re pleased to tell you that {orgDisplay} has a <span className="bold">special offer</span> just for you.
            </Text>

            <LinkButtonStyled as="button" onClick={() => handleReceiveOfferClick()}>
              Receive your offer
            </LinkButtonStyled>

            {orgLogo.localFile
               && (
               <Box mt={[`28px`, null, null, `36px`]}>
                 <Text fontSize={theme.rem12} mb="6px">
                        In Partnership with:
                 </Text>
                 <Box mx="auto" width={[`70px`, null, `80px`]}>
                   <Image
                     fluid={orgLogo.localFile.childImageSharp.fluid}
                     alt={orgLogo.alt}
                   />
                 </Box>
               </Box>
               )}       
          </Box>

          <Box gridColumn={[`1 / span 12`, `2 / span 10`]} zIndex="10" mt="54px" className="posRelative">
            <VideoWrap>
              {showCover
                && (
                  <Absolute width="100%" height="100%" zIndex="5" onClick={() => coverClicked(player)}>
                    <SvgAbsolute>
                      <PlayButton size="l" />
                    </SvgAbsolute>
                    <Image fluid={data.videoCover.childImageSharp.fluid} height="100%" alt="Child practicing techniques through TopTekkers" />
                  </Absolute>
                )
              }
              <Video
                borderRadius={theme.borderRadiusContent}
                shadow="0px 0px 18px 0px rgba(0,0,0,0.3)"
              >
                <div id="youtube-player" />
              </Video>
            </VideoWrap>
          </Box>
        </GridCenteredItems>

        <HeroWaveBox bg={theme.green} pb={[`90px`, null, null, `200px`]}>
          <Wave fill={theme.white} />
        </HeroWaveBox>
      </Section>

      {/* 2. About */}
      <Section bg={theme.green} zIndex="1" pt={[`53px`, `164px`]} pb="0">
        <Grid pb={[`60px`, `164px`]} gridRowGap={[0, `80px`, `130px`]}>
          {/* All Box in this Grid needed the order property to correctly work in Players section */}
          {/* Clubs */}
          <Box order="1" gridColumn={[`1 / span 4`, `1 / span 4`, null, `1 / span 3`]}>
            <Image fluid={data.clubBadge.childImageSharp.fluid} alt="TopTekkers club badge." />
          </Box>

          <Box order="2" gridColumn={[`1 / span 12`, `6 / span 7`]} pt={[`16px`, 0]} pb={[`36px`, 0]}>
            <Heading as="h2" color={theme.white}>Why we have chosen TopTekkers…</Heading>
            <Text color={theme.white}>By practicing their skills at home, training sessions will become more productive and enjoyable.</Text>
          </Box>

          {/* Players  */}
          <Box order={[3, 4]} gridColumn={[`1 / span 4`, `9 / span 4`, null, `10 / span 3`]}> 
            <Image fluid={data.Skills.childImageSharp.fluid} alt="TopTekkers app skills category." />
          </Box>

          <Box order={[4, 3]} gridColumn={[`1 / span 12`, `1 / span 7`]} pt={[`16px`, 0]} pb={[`36px`, 0]}>
            <Heading as="h2" color={theme.white}>How does it benefit our players?</Heading>
            <Text color={theme.white}>Players find it fun and engaging, it improves health and wellbeing and makes better football players. Research shows that 91% of children enjoy TopTekkers and improve their ability by 17% in just one month!</Text>
          </Box>
        
          {/* Parents/Coaches */}
          <Box order="5" gridColumn={[`1 / span 4`, `1 / span 4`, null, `1 / span 3`]}>
            <Image fluid={data.holdingCup.childImageSharp.fluid} alt="TopTekkers avatar holds up a winners cup." />
          </Box>

          <Box order="6" gridColumn={[`1 / span 12`, `6 / span 7`]} pt={[`16px`, 0]}>
            <Heading as="h2" color={theme.white}>Benefits to Parents</Heading>
            <Text color={theme.white}>TopTekkers is easy to use and promotes a healthy use of technology for children. The challenges enable progression and skills improvement whilst keeping children active and engaged.</Text>
          </Box>
        </Grid>

        <WaveBox bg={theme.white}>
          {/* 
            https://stackoverflow.com/questions/46156669/safari-flex-item-unwanted-100-width-css

            Safari flexbox bug: some elements are unable to be flex parents
            (see https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers).
            You can work around the issue by nesting a div within.
          */}
          <Box flex="1" width="100%">
            <Wave fill={theme.green} />
          </Box>
        </WaveBox>
      </Section>

      {/* 3. Quote */}
      <Section bg={theme.white} pt={[`80px`, `100px`, `170px`, `280px`]} pb="0">
        <Container>
          <Quote as="p" pb={[null, null, `100px`, `206px`]} mb="0" mx="auto" textAlign="center" maxWidth="992px">
          TopTekkers is a great tool for coaches and clubs because it guides the player&#8217;s learning. If you are busy with everyday life, <span>you can be confident</span> that when your child is using TopTekkers, they are <span>learning the game in the right way</span>.
          </Quote>
        </Container>

        <WaveBoxFlipped bg={theme.purpleLight}>
          {/* 
            https://stackoverflow.com/questions/46156669/safari-flex-item-unwanted-100-width-css

            Safari flexbox bug: some elements are unable to be flex parents
            (see https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers).
            You can work around the issue by nesting a div within.
          */}
          <Box flex="1" width="100%">
            <Wave fill={theme.white} />
          </Box>
        </WaveBoxFlipped>
      </Section>
      
      {/* 4. Portals */}
      <Section bg={theme.purpleLight} pt={[`80px`, `100px`, `177px`]} pb={[`106px`, null, `120px`]}>        
        <GridCenteredItems alignItems="start">
          <Box gridColumn={[`1 / span 12`, `3 / span 8`]} mb="70px" zIndex="5">
            <Heading as="h2" maxWidth="none" color={theme.white} fontSize={[`32px`, null, `38px`, null, `48px`]}>How we prepare players</Heading>
            <Text color={theme.white} fontSize={theme.rem18} lineHeight="28px">TopTekkers gives the ability to interact with each other through development plans, progress tracking, and winning trophies</Text>
          </Box>

          <PortalType fixedImg={data.parentPortal} heading="Parent Portal" headingAs="h3" gridColumn={[`1 / span 12`, null, `1 / span 4`]} zIndex="5">
            Parents use their account to monitor their child’s progress, control child’s login details and approve coach connections for their child.
          </PortalType>

          <PortalType fixedImg={data.childPortal} heading="Child Portal" headingAs="h3" gridColumn={[`1 / span 12`, null, `5 / span 4`]} my={[`60px`, null, 0]} zIndex="5">
            Your child can view tutorial videos, practice techniques, and see specific training goals set by their coach. Completing challenges unlocks trophies and rewards, and they can see their progress on leaderboards.
          </PortalType>

          <PortalType fixedImg={data.coachPortal} heading="Coach Portal" headingAs="h3" gridColumn={[`1 / span 12`, null, `9 / span 4`]} zIndex="5">
            Coaches can set development plans for their teams and players, view each child&#8217;s progress and trophy count, and manage leaderboards. As we value your privacy, coaches can only communicate with your child through challenges.
          </PortalType>
        </GridCenteredItems>


        {/* Circles */}
        <MaxWidthAbsolute>
          <Circle 
            grow
            bgColor={theme.purpleDark}
            right={[-30, null, -46, -86]}
            bottom="26%"
            hideOnMobile
          />
          <Circle 
            size="xl"
            bgColor={theme.purpleDark}
            left={[-180, -146]}
            top={[300, null, -46, 0]}
          />

          {/* Desktop only Circles */}
          <Circle 
            width="44px"
            height="44px"
            bgColor={theme.purpleDark}
            left="20%"
            top="20%"
            desktopOnly
          />
          <Circle 
            size="s"
            bgColor={theme.purpleDark}
            left={[`10%`, null, null, `20%`]}
            bottom="20%"
            hideOnMobile
          />
          <Circle 
            width="78px"
            height="78px"
            bgColor={theme.purpleDark}
            right="14%"
            top="18%"
            hideOnMobile
          />

          {/* Mobile only Circles */}
          <Circle 
            size="s"
            bgColor={theme.purpleDark}
            right="30px"
            top={[300, null, 46, 100]}
            hideOnTablet
          />
          <Circle 
            size="xs"
            bgColor={theme.purpleDark}
            left="26%"
            top="44%"
            hideOnTablet
          />
          <Circle 
            size="s"
            bgColor={theme.purpleDark}
            right="12%"
            top="50%"
            hideOnTablet
          />
          <Circle 
            size="s"
            bgColor={theme.purpleDark}
            left="6%"
            bottom="20%"
            hideOnTablet
          />
          <Circle 
            size="xl"
            bgColor={theme.purpleDark}
            right="-200px"
            bottom="4%"
            hideOnTablet
          />
        </MaxWidthAbsolute>

        <BubbleShapeAbsolute>
          <Image fluid={data.bubbleEllipse.childImageSharp.fluid} alt="Background bubble shapes" />
        </BubbleShapeAbsolute>
      </Section>
      
      {/* 5. Testimonial Carousel */}
      <Section pb="0" mb="100px">
        <Box bg={theme.purpleLight}>
          <Container pb="300px">
            <Heading as="h2" zIndex="10" color={theme.white} mx="auto" textAlign="center" maxWidth="800px" mb="0" pb={[`20px`, null, `72px`]}>Used by your child&#8217;s club as well
as many others around the world
            </Heading>
          </Container>
        </Box>

        <Box maxWidth="1600px" mx="auto" mt="-300px">
          <TestimonialCarousel testimonialList={testimonialList} />
        </Box>
      </Section>

      {/* 6. App */}
      <Section pt={[`92px`, null, `100px`]}>
        <Container gridColumn={[`1 / span 12`, null, `2 / span 10`]}>
          <Heading as="h2" mb={[`32px`, null, `54px`]} mx="auto" textAlign="center" maxWidth="none">
            Practice on the go with our app
          </Heading>
        </Container>
      
        <AboutApp centerImg={data.app.childImageSharp.fluid} />
        <Box maxWidth="420px" mx="auto" display={[`none`, null, null, `block`]}>
          <AppStoreLinks itemWidth="32%" mt="42px" justifyContent="space-between" />  
        </Box>


        {/* Show this on mobile */}
        <AboutAppGrid>
          <Box gridColumn={[`3 / span 8`, null, `4 / span 6`]}>
            <Image fluid={data.app.childImageSharp.fluid} alt="Shows a technique within the TopTekkers app" /> 
          </Box>

          <Box gridColumn={[`1 / span 12`, null, `3 / span 8`]} mt={[`45px`, null, null, 0]}>
            <AboutAppCarousel imgFluid={data.app.childImageSharp.fluid} />
          </Box>
        </AboutAppGrid>
      </Section>

      {/* 7. CTA */}
      <Section pt={[`30px`, null, null, 0]}>
        <Grid id="refCTA">
          <ReferralCTA
            mainText={ctaTitle.text}
            subText={ctaPara}
            organisation={orgTag}
            gridColumn="1 / span 12"
            bg={theme.green}
            borderRadius={theme.borderRadiusContent}
            bgImg={data.pitchLines.childImageSharp.fluid}
            buttonData={buttonData}
          >
            Hello
          </ReferralCTA>
        </Grid>
      </Section>
    </Layout>
  );
};

LandingPageReferral.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export const query = graphql`
  query($UID: String!) {
    prismicLandingPageReferral(uid: { eq: $UID }) {
      uid
      data {
        hero_title {
          text
        }
        hero_paragraph {
          html
        }
        video
        organisation_logo {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        org_display_name
        org_tag
        cta_title {
          text
        }
        cta_paragraph
        button_text
        button_link
      }
    }
    testimonials: allPrismicLandingPageReferral(filter: {uid: {eq: $UID}}) {
      edges {
        node {
          data {
            testimonials {
              document {
                ... on PrismicTestimonialList {
                  data {
                    testimonial_list {
                      testimonial {
                        document {
                          ... on PrismicTestimonial {
                            data {
                              highlight
                              testimonial
                              profile_image {
                                alt
                                localFile {
                                  childImageSharp {
                                    fixed(width: 68) {
                                      ...GatsbyImageSharpFixed_noBase64
                                    }
                                  }
                                }
                              }
                              profile_name
                              profile_title
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    clubBadge: file(relativePath: { eq: "images/club_badge.png" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    Skills: file(relativePath: { eq: "images/Skills.png" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    holdingCup: file(relativePath: { eq: "images/holding_cup.png" }) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    parentPortal: file(relativePath: { eq: "images/parentPlanImg.png" }) {
      childImageSharp {
        fixed(width: 110) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    childPortal: file(relativePath: { eq: "images/childPortal.png" }) {
      childImageSharp {
        fixed(width: 110) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    coachPortal: file(relativePath: { eq: "images/coachPlanImg.png" }) {
      childImageSharp {
        fixed(width: 110) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    bubbleEllipse: file(relativePath: { eq: "images/bubbleEllipse.png" }) {
      childImageSharp {
        fluid(maxWidth: 780) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    app: file(relativePath: { eq: "images/app.png" }) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pitchLines: file(relativePath: { eq: "images/Pitch.png" }) {
      childImageSharp {
        fluid(maxWidth: 1300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoCover: file(relativePath: { eq: "images/VideoCover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    } 
  }
`;

export default withTheme(LandingPageReferral);
