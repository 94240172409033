import { useState, useEffect } from "react";

// https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
const useWindowWidth = () => {
  // To prevent against "window is not available during server side rendering"
  // error when building the site
  const [width, setWidth] = useState(typeof window !== `undefined` ? window.innerWidth : 0);
  
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener(`resize`, handleResize);
    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  });
  
  return width;
};

export default useWindowWidth;