import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose, grid, alignItems, layout, space, maxWidth } from "styled-system";


// Set a gridTemplateColumns when using.
const Grid = styled.div(
  compose(
    grid,
    alignItems,
    layout,
    space,
    maxWidth,
  ),
);

Grid.defaultProps = {
  gridTemplateColumns: `repeat(12, 1fr)`,
  display: `grid`,
  gridColumnGap: [0, `44px`],
  alignItems: `center`,
  maxWidth: `1200px`,
  px: [`14px`, null, `22px`, null, null, 0],
  mx: `auto`,
};

export default Grid;