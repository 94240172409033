import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Box from "../../containers/box";
import Heading from "../../typography/heading";
import Text from "../../typography/text";
import Grid from "../../containers/grid";
import Image from "../../globals/image";

const Root = styled(Grid)`
  display: none;

  @media (min-width: ${p => p.theme.screenL}) {
    display: grid;

    h3 {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 18px;
    }
  
    div {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }
`;

const AboutApp = ({ centerImg, theme }) => {
  return (
    <Root>
      <Box gridColumn={[`1 / span 12`, `1 / span 4`]}>
        <Box mb="150px">
          <Heading as="h3">Watch technique videos</Heading>
          <Text fontSize={theme.rem18} lineHeight="28px">
            Technique and fitness videos prepare your children for every aspect of the game.
          </Text>
        </Box>
          
        <Box>
          <Heading as="h3">Try the challenges</Heading>
          <Text fontSize={theme.rem18} lineHeight="28px">
            The challenges allow your child to improve technique, have fun and monitor progress.
          </Text>
        </Box>
      </Box>

      <Box gridColumn={[`1 / span 12`, `5 / span 4`]}>
        <Image fluid={centerImg} alt="Shows a technique within the TopTekkers app" /> 
      </Box>

      <Box gridColumn={[`1 / span 12`, `9 / span 4`]}>
        <Box mb="150px">
          <Heading as="h3">Unlock trophies</Heading>
          <Text fontSize={theme.rem18} lineHeight="28px">
            Your child can unlock trophies as they achieve certain targets within the challenges.
          </Text>
        </Box>

        <Box>
          <Heading as="h3">Training from coaches</Heading>
          <Text fontSize={theme.rem18} lineHeight="28px">
            Coaches create team and player development plans to improve individuals and ultimately benefitting the whole team.
          </Text>
        </Box>
      </Box>
    </Root>
  );
};

AboutApp.propTypes = {
  centerImg: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(AboutApp);