import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import Box from "../../containers/box";
import Heading from "../../typography/heading";
import Text from "../../typography/text";
import Image from "../../globals/image";

const PortalType = ({ fixedImg, heading, headingAs, children, theme, ...rest }) => {
  return (
    <Box {...rest}>
      <Image fixed={fixedImg.childImageSharp.fixed} alt="TopTekkers avatars" />
      <Heading as={headingAs} color={theme.white} fontSize={[`28px`, null, `30px`]} mt="26px" mb="18px" maxWidth="none">{heading}</Heading>
      <Text color={theme.white} fontSize={theme.rem18} lineHeight="28px">{children}</Text>
    </Box>
  );
};

PortalType.propTypes = {
  fixedImg: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  headingAs: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(PortalType);