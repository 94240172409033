import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import NukaCarousel from "nuka-carousel";

import useWindowWidth from "../../../hooks/useWindowWidth";
import Box from "../../containers/box";
import Text from "../../typography/text";
import Image from "../../globals/image";
import { Absolute } from "../../containers/position";

const Root = styled(NukaCarousel)`
  .slider-control-bottomcenter {
    bottom: -56px !important;

    @media (min-width: ${p => p.theme.screenM}) {
      bottom: -64px !important;
    }
  }

  .paging-dot {
    margin: 0 4px;
    width: ${p => `${p.dotSize}px`};
    height: ${p => `${p.dotSize}px`};

    circle {
      r: ${p => `${p.dotSize / 2}px`};
      cx: ${p => `${p.dotSize / 2}px`};
      cy: ${p => `${p.dotSize / 2}px`};
    }
  }

  .active svg {
    fill: ${p => p.theme.green};

    > * {
      width: 100%;
    }
  }

  .slide-current .overlay {
    opacity: 0;
    visibility: hidden;
  }

  li:focus {
    outline: none;
  }  
`;

const Testimonial = styled(Box)`
  max-width: 598px;
  background-color: ${p => p.theme.white};
  border-radius: ${p => p.theme.borderRadiusContent};
  box-shadow: -2px -1px 18px 0px rgba(0,0,0,0.3);
  text-align: center;
  box-shadow: -2px -1px 18px 0px rgba(0,0,0,0.3);
  position: relative;

  .paging-dot {
    width: ${p => `${p.dotSize}px`};
    height: ${p => `${p.dotSize}px`};

    circle {
      r: ${p => `${p.dotSize / 2}px`};
      cx: ${p => `${p.dotSize / 2}px`};
      cy: ${p => `${p.dotSize / 2}px`};
    }
  }

  div:last-of-type {
    opacity: 0;

    @media (min-width: ${p => p.theme.screenL}) {
      opacity: .8;
    }
  }
`;

// https://github.com/FormidableLabs/nuka-carousel/issues/691
const TestimonialOverlay = styled(Absolute)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${p => p.theme.white};
  z-index: 10;
  border-radius: ${p => p.theme.borderRadiusContent};
  transition: ${p => p.theme.transitionDefault};
  visibility: visible;
`;

const TestimonialCarousel = ({ testimonialList, theme }) => {
  const windowWidth = useWindowWidth();
  let slidesToShowNum = 1;
  let slideWidthNum = 0.9;

  if (windowWidth >= 1024) {
    slidesToShowNum = 3;
    slideWidthNum = 1.5;
  }

  return (
    <Root
      renderCenterLeftControls={() => null}
      renderCenterRightControls={() => null}
      defaultControlsConfig={{
        pagingDotsStyle: {
          fill: `#C4C4C4`,
        },
      }}
      dotSize={10}
      cellSpacing={80}
      cellAlign="center"
      heightMode="current"
      speed={900}
      slideWidth={slideWidthNum}
      slidesToShow={slidesToShowNum}
      frameOverflow="visible"
    >
      {testimonialList.map((item) => {
        const { data } = item.testimonial.document;
        const { fixed } = data.profile_image.localFile.childImageSharp;

        return (
          <Testimonial key={data.profile_title} px={[`20px`, null, `56px`]} pt="20px" pb="30px" mx="auto">
            <Image fixed={fixed} alt={data.profile_image.alt} mb="20px" />
            <Text as="h3" fontFamily={theme.baseFont} fontWeight="normal" fontSize="22px" lineHeight="33px" color={theme.blueDark} mb={[`14px`, null, `20px`]}>{data.highlight}</Text>

            <blockquote>
              <Text fontSize="16px" lineHeight="31px" mb={[`14px`, null, `20px`]}>
                {data.testimonial}
              </Text>

              <footer>
                <Text as="span" display="block" fontFamily={theme.headingFont} fontSize={[`20px`, null, `22px`]} lineHeight={[`36px`, null, `42px`]} color={theme.blueDark} mb="0">{data.profile_name}</Text>
                <Text as="cite" fontSize="16px" lineHeight="28px" mb={0}>{data.profile_title}</Text>
              </footer>
            </blockquote>


            <TestimonialOverlay className="overlay" />
          </Testimonial>
        );
      })}
    </Root>
  );
};

TestimonialCarousel.propTypes = {
  testimonialList: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(TestimonialCarousel);